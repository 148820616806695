export const getServiceUrlForEnv = (serviceKey, env) => {
  if (env === 'local') {
    // todo: we need to move away from magic ports
    const localServiceMap = {
      api: 'http://localhost:8080',
      assets: 'http://localhost:7111',
      retool: 'http://localhost:3000',
      admin: 'http://localhost:4000',
      auth: 'auth.dev.wrkvbs.io'
    }

    return localServiceMap[serviceKey]
  }

  if (env === 'prod') {
    return `https://${serviceKey}.wrkvbs.io`
  }

  // staging, dev, test etc. is default
  return `https://${serviceKey}.${env}.wrkvbs.io`
}

const getAwsConfigForEnv = (env) => {
  const envMap = {
    default: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_xWQA5al3X',
      cognitoClientId: '6dc26ec0kll2o3dqkbtnode061'
    },
    staging: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_tYp3AzGVF',
      cognitoClientId: '6im1nuebed9iqp2k6ktfmqb9mk'
    },
    prod: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_5wu9O1wFy',
      cognitoClientId: '3bbc2r8r856b934gm0c8fioq0d'
    }
  }

  return envMap[env] || envMap.default
}

export const getConfig = () => {
  let env = process.env.TARGET_ENV || 'local'

  try {
    const host = document.location.hostname
    const segments = host.split('.')

    if (segments.length > 1) {
      env = 'prod'
    }

    if (segments.length > 3) {
      env = segments[1]
    }
  } catch {} // todo: handle the config for SSR where document is unavailable

  const serviceEnv = env === 'local' ? 'dev' : env
  const awsConfig = getAwsConfigForEnv(env)

  return {
    ...awsConfig,
    serviceEnv,
    apiUrl: `${getServiceUrlForEnv('api', env)}${env !== 'local' ? '/1/' : ''}`,
    retoolUrl: getServiceUrlForEnv('retool', env),
    assetsUrl: getServiceUrlForEnv('assets', env),
    adminUrl: getServiceUrlForEnv('admin', env),
    authDomain: getServiceUrlForEnv('auth', env)
  }
}
